import React from 'react';
import './style.scss';

const RecruiterSection = () => (
  <aside className="recruiter-section">
    <p>Your header here</p>
  </aside>
);

export default RecruiterSection;
