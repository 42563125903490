export default [
  {
    institutionName: 'Northwestern University',
    dates: '5/2014',
    details: ['Masters in Informatics']
  },
  {
    institutionName: 'Mississippi College',
    dates: '12/1999',
    details: ['Bachelor of Science, Computer Information Systems (CIS)']
  },
  {
    institutionName: 'Delta State University',
    dates: '5/1994',
    details: ['Bachelor of Science']
  }
]
