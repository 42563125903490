import React from 'react';
import { Tidbit, AssetIcon, Reflex } from '..';
import './style.scss';

const Header = () => (
  <header className="header">
    <div className="header__left">
      <div className="header__title">
        <h1>James "Buddy" Fortner</h1>
        <em>Over 25 yrs of software development experience.</em>
      </div>
      <address className="header__contact-me">
        <Reflex classModifiers="reflex--phone header__chip-row">
          <Tidbit
            Icon={() => <AssetIcon name="link-solid.svg" />}
            title="buddy.fortners.net"
            href="http://buddy.fortners.net"
          />
          <Tidbit
            Icon={() => <AssetIcon name="at-solid.svg" />}
            title="buddy@fortners.net"
            href="mailto:buddy@fortners.net"
          />
        </Reflex>
      </address>
    </div>
    <div className="header__right">
      <div className="header__avatar">
        <img
          alt="Buddy Fortner"
          src="http://buddy.fortners.net/imgs/buddy_f.png" 
          width="150"
        />
      </div>
    </div>
  </header>
);

export default Header;
